import { Dayjs } from 'dayjs'

import { DEFAULT_FORMAT } from './Constant'
import { RidaApiService } from './RidaApi'
import { V1RidaApiGetReportEarningForDateRangeResponse } from './Type'

class Report {
  public static async generateEarningForDateRange(
    startDate: Dayjs,
    endDate: Dayjs,
  ) {
    const response =
      await RidaApiService.get<V1RidaApiGetReportEarningForDateRangeResponse>(
        'v1/report/earningForDateRange',
        {
          start_date: startDate.format(DEFAULT_FORMAT.DATE),
          end_date: endDate.format(DEFAULT_FORMAT.DATE),
        },
      )

    return response.data.data
  }

  public static async triggerBatching(date: Dayjs) {
    await RidaApiService.post('v1/report/triggerBatching', {
      date: date.format(DEFAULT_FORMAT.DATE),
    })
  }
}

export { Report as ReportService }
