import { RidaApiService } from './RidaApi'

class Task {
  public static async updateTaskDeliveryStatus(
    taskId: string,
    options: {
      taskDeliveryStatusTypeId: number
    },
  ) {
    await RidaApiService.put(`v1/task/${taskId}/deliveryStatus`, {
      task_delivery_status_type_id: options.taskDeliveryStatusTypeId,
    })
  }
}

export { Task as TaskService }
