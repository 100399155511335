import _ from 'lodash'
import Pusher from 'pusher-js'

import { UtilityService } from './Utility'

class PusherApi {
  public pusher: Pusher

  constructor() {
    const config = UtilityService.getConfig()
    this.pusher = new Pusher(config.PUSHER.API_KEY, {
      cluster: config.PUSHER.CLUSTER,
    })
  }

  public subscribe(channel: string) {
    return this.pusher.subscribe(channel)
  }

  public unsubscribe(channels: string[]) {
    _.each(channels, channel => {
      this.pusher.unsubscribe(channel)
    })
  }
}

const pusherApi = new PusherApi()
export { pusherApi as PusherApiService }
