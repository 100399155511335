import axios from 'axios'
import _ from 'lodash'
import qs from 'qs'

import { FirebaseService } from './Firebase'
import { UtilityService } from './Utility'

class RidaApi {
  public static async get<Response>(
    url: string,
    queryString?: Record<string, unknown>,
  ) {
    const envConfig = UtilityService.getConfig()
    const baseUrl = envConfig.RIDA.BACKEND_URL
    const accessToken = await FirebaseService.getAccessToken()
    const headers = {
      'x-app-version': envConfig.TAG.APP_VERSION,
      'x-os': envConfig.TAG.OS,
    }

    if (accessToken) {
      _.extend(headers, { Authorization: accessToken })
    }

    return axios.get<Response>(`${baseUrl}/${url}`, {
      params: queryString,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
      headers,
    })
  }

  public static async post<Response>(
    url: string,
    body?: Record<string, unknown>,
  ) {
    const envConfig = UtilityService.getConfig()
    const baseUrl = envConfig.RIDA.BACKEND_URL
    const accessToken = await FirebaseService.getAccessToken()
    const headers = {
      'x-app-version': envConfig.TAG.APP_VERSION,
      'x-os': envConfig.TAG.OS,
    }

    if (accessToken) {
      _.extend(headers, { Authorization: accessToken })
    }

    return axios.post<Response>(`${baseUrl}/${url}`, body, {
      headers,
    })
  }

  public static async put<Response>(
    url: string,
    body?: Record<string, unknown>,
  ) {
    const envConfig = UtilityService.getConfig()
    const baseUrl = envConfig.RIDA.BACKEND_URL
    const accessToken = await FirebaseService.getAccessToken()
    const headers = {
      'x-app-version': envConfig.TAG.APP_VERSION,
      'x-os': envConfig.TAG.OS,
    }

    if (accessToken) {
      _.extend(headers, { Authorization: accessToken })
    }

    return axios.put<Response>(`${baseUrl}/${url}`, body, {
      headers,
    })
  }

  public static async delete<Response>(
    url: string,
    body?: Record<string, unknown>,
  ) {
    const envConfig = UtilityService.getConfig()
    const baseUrl = envConfig.RIDA.BACKEND_URL
    const accessToken = await FirebaseService.getAccessToken()
    const headers = {
      'x-app-version': envConfig.TAG.APP_VERSION,
      'x-os': envConfig.TAG.OS,
    }

    if (accessToken) {
      _.extend(headers, { Authorization: accessToken })
    }

    return axios.request<Response>({
      method: 'DELETE',
      url: `${baseUrl}/${url}`,
      headers,
      data: body,
    })
  }

  public static async getSassFleetOperator<Response>(
    url: string,
    queryString?: Record<string, unknown>,
  ) {
    const envConfig = UtilityService.getConfig()
    const baseUrl = envConfig.RIDA.SASS_FLEET_OPERATOR_BACKEND_URL
    const accessToken = await FirebaseService.getAccessToken()
    const headers = {
      'x-app-version': envConfig.TAG.APP_VERSION,
      'x-os': envConfig.TAG.OS,
    }

    if (accessToken) {
      _.extend(headers, { Authorization: accessToken })
    }

    return axios.get<Response>(`${baseUrl}/${url}`, {
      params: queryString,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
      headers,
    })
  }

  public static async postSassFleetOperator<Response>(
    url: string,
    body?: Record<string, unknown>,
  ) {
    const envConfig = UtilityService.getConfig()
    const baseUrl = envConfig.RIDA.SASS_FLEET_OPERATOR_BACKEND_URL
    const accessToken = await FirebaseService.getAccessToken()
    const headers = {
      'x-app-version': envConfig.TAG.APP_VERSION,
      'x-os': envConfig.TAG.OS,
    }

    if (accessToken) {
      _.extend(headers, { Authorization: accessToken })
    }

    return axios.post<Response>(`${baseUrl}/${url}`, body, {
      headers,
    })
  }

  public static async putSassFleetOperator<Response>(
    url: string,
    body?: Record<string, unknown>,
  ) {
    const envConfig = UtilityService.getConfig()
    const baseUrl = envConfig.RIDA.SASS_FLEET_OPERATOR_BACKEND_URL
    const accessToken = await FirebaseService.getAccessToken()
    const headers = {
      'x-app-version': envConfig.TAG.APP_VERSION,
      'x-os': envConfig.TAG.OS,
    }

    if (accessToken) {
      _.extend(headers, { Authorization: accessToken })
    }

    return axios.put<Response>(`${baseUrl}/${url}`, body, {
      headers,
    })
  }

  public static async deleteSassFleetOperator<Response>(
    url: string,
    body?: Record<string, unknown>,
  ) {
    const envConfig = UtilityService.getConfig()
    const baseUrl = envConfig.RIDA.SASS_FLEET_OPERATOR_BACKEND_URL
    const accessToken = await FirebaseService.getAccessToken()
    const headers = {
      'x-app-version': envConfig.TAG.APP_VERSION,
      'x-os': envConfig.TAG.OS,
    }

    if (accessToken) {
      _.extend(headers, { Authorization: accessToken })
    }

    return axios.request<Response>({
      method: 'DELETE',
      url: `${baseUrl}/${url}`,
      headers,
      data: body,
    })
  }
}

export { RidaApi as RidaApiService }
