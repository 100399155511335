import { RidaApiService } from '../RidaApi'

class SassFleetOperatorTask {
  public static async updateTaskDeliveryStatus(
    taskId: string,
    options: {
      taskDeliveryStatusTypeId: number
    },
  ) {
    await RidaApiService.putSassFleetOperator(
      `v1/task/${taskId}/deliveryStatus`,
      {
        task_delivery_status_type_id: options.taskDeliveryStatusTypeId,
      },
    )
  }
}

export { SassFleetOperatorTask as SassFleetOperatorTaskService }
