import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth'

import { FirebaseService } from './Firebase'

class Auth {
  public static login(email: string, password: string) {
    return signInWithEmailAndPassword(FirebaseService.auth, email, password)
  }

  public static forgotPassword(email: string) {
    return sendPasswordResetEmail(FirebaseService.auth, email)
  }
}

export { Auth as AuthService }
