import { Dayjs } from 'dayjs'

import { DEFAULT_FORMAT } from '../Constant'
import { RidaApiService } from '../RidaApi'
import {
  V1RidaApiGetSassFleetOperatorDriverEarningResponse,
  V1RidaApiGetSassFleetOperatorJobIdDriverEarningResponse,
} from '../Type'

class SassFleetOperatorDriverEarning {
  public static async getAllDriverEarningsForDateRange(
    startDate: Dayjs,
    endDate: Dayjs,
    filterOptions?: {
      pageNumber?: number
      itemsPerPage?: number
      search?: string
    },
  ) {
    const response =
      await RidaApiService.getSassFleetOperator<V1RidaApiGetSassFleetOperatorDriverEarningResponse>(
        'v1/driverEarning',
        {
          start_date: startDate.format(DEFAULT_FORMAT.DATE),
          end_date: endDate.format(DEFAULT_FORMAT.DATE),
          items_per_page: filterOptions?.itemsPerPage,
          page_number: filterOptions?.pageNumber,
          search: filterOptions?.search,
        },
      )

    return response.data.data
  }

  public static async getAllEarningsForJob(jobId: string) {
    const response =
      await RidaApiService.getSassFleetOperator<V1RidaApiGetSassFleetOperatorJobIdDriverEarningResponse>(
        `v1/job/${jobId}/driverEarning`,
      )

    return response.data.data
  }

  public static async createDriverEarning(data: {
    driverEarningTypeId: number
    amount: string
    comment: string | null
    taskId: string
  }) {
    await RidaApiService.postSassFleetOperator('v1/driverEarning', {
      driver_earning_type_id: data.driverEarningTypeId,
      amount: data.amount,
      comment: data.comment,
      task_id: data.taskId,
    })
  }

  public static async updateDriverEarningById(
    driverEarningId: string,
    data: {
      driverEarningTypeId: number
      amount: string
      comment: string | null
    },
  ) {
    await RidaApiService.putSassFleetOperator(
      `v1/driverEarning/${driverEarningId}`,
      {
        driver_earning_type_id: data.driverEarningTypeId,
        amount: data.amount,
        comment: data.comment,
      },
    )
  }

  public static async deleteDriverEarningById(
    driverEarningId: string,
    data: {
      comment: string | null
    },
  ) {
    await RidaApiService.deleteSassFleetOperator(
      `v1/driverEarning/${driverEarningId}`,
      {
        comment: data.comment,
      },
    )
  }
}

export { SassFleetOperatorDriverEarning as SassFleetOperatorDriverEarningService }
