import _ from 'lodash'

import { RidaApiService } from '../RidaApi'
import {
  V1RidaApiGetSassFleetOperatorTypeResponse,
  V1RidaApiGetSassFleetOperatorUserGroupResponse,
  V1RidaApiGetSassFleetOperatorUserOnlineResponse,
  V1RidaApiGetSassFleetOperatorUserResponse,
  V1RidaApiGetSassFleetOperatorUserSearchResponse,
} from '../Type'

class SassFleetOperatorUser {
  public static async getAllUsers(filterOptions?: {
    search?: string
    itemsPerPage?: number
    pageNumber?: number
  }) {
    const response =
      await RidaApiService.getSassFleetOperator<V1RidaApiGetSassFleetOperatorUserResponse>(
        'v1/user',
        {
          search: filterOptions?.search,
          items_per_page: filterOptions?.itemsPerPage,
          page_number: filterOptions?.pageNumber,
        },
      )

    return response.data.data
  }

  public static async importUsers(
    userData: {
      email: string
      password: string
      phoneNumber: string
      firstName: string
      lastName: string
      preferredName: string
      userVehicleTypeId: string
      userVehicleRegistrationNumber: string
      bankId: string | null
      bankAccountNumber: string | null
      roleTypeId: string | null
      groupCodes: string[]
    }[],
  ) {
    await RidaApiService.postSassFleetOperator('v1/userBulk', {
      users: _.map(userData, user => {
        return {
          email: user.email,
          password: user.password,
          first_name: user.firstName,
          last_name: user.lastName,
          preferred_name: user.preferredName,
          phone_number: user.phoneNumber,
          user_vehicles: [
            {
              vehicle_type_id: user.userVehicleTypeId,
              vehicle_registration_number: user.userVehicleRegistrationNumber,
            },
          ],
          user_bank_accounts:
            user.bankId && user.bankAccountNumber
              ? [
                  {
                    bank_id: user.bankId,
                    bank_account_number: user.bankAccountNumber,
                  },
                ]
              : [],
          user_roles: user.roleTypeId
            ? [{ user_role_type_id: user.roleTypeId }]
            : [],
          user_groups: _.map(user.groupCodes, c => {
            return {
              group_code: c,
            }
          }),
        }
      }),
    })
  }

  public static async createSingleUser(userData: {
    email: string
    password: string
    phoneNumber: string
    firstName: string
    lastName: string
    preferredName: string
    userVehicleTypeId: number
    userVehicleRegistrationNumber: string
    bankId: number | null
    bankAccountNumber: string | null
    roleTypeId: number
    groupIds: string[]
    isAutoAssignEnabled: boolean
  }) {
    await RidaApiService.postSassFleetOperator('v1/user', {
      email: userData.email,
      password: userData.password,
      first_name: userData.firstName,
      last_name: userData.lastName,
      preferred_name: userData.preferredName,
      phone_number: userData.phoneNumber,
      is_auto_assign_enabled: userData.isAutoAssignEnabled,
      user_vehicles: [
        {
          vehicle_type_id: userData.userVehicleTypeId,
          vehicle_registration_number: userData.userVehicleRegistrationNumber,
        },
      ],
      user_bank_accounts:
        userData.bankId && userData.bankAccountNumber
          ? [
              {
                bank_id: userData.bankId,
                bank_account_number: userData.bankAccountNumber,
              },
            ]
          : [],
      user_roles: [{ user_role_type_id: userData.roleTypeId }],
      user_groups: _.map(userData.groupIds, g => {
        return {
          group_id: g,
        }
      }),
    })
  }

  public static async getAllTypes() {
    const response =
      await RidaApiService.getSassFleetOperator<V1RidaApiGetSassFleetOperatorTypeResponse>(
        'v1/type',
      )

    return response.data.data
  }

  public static async getAllOnlineUsers(filterOptions?: {
    search?: string
    itemsPerPage?: number
    pageNumber?: number
  }) {
    const response =
      await RidaApiService.getSassFleetOperator<V1RidaApiGetSassFleetOperatorUserOnlineResponse>(
        'v1/user/online',
        {
          search: filterOptions?.search,
          items_per_page: filterOptions?.itemsPerPage,
          page_number: filterOptions?.pageNumber,
        },
      )

    return response.data.data
  }

  public static async updateUserStatus(
    userId: string,
    userStatusTypeId: number,
  ) {
    await RidaApiService.putSassFleetOperator(`v1/user/${userId}/status`, {
      user_status_type_id: userStatusTypeId,
    })
  }

  public static async updateSingleUserDetails(
    userId: string,
    details: {
      firstName: string | null
      lastName: string | null
      preferredName: string | null
      email: string
      phoneNumber: string | null
      userRoleTypeId: number | null
      vehicleTypeId: number | null
      vehicleRegistrationNumber: string | null
      bankId: number | null
      bankAccountNumber: string | null
      groupIds: string[]
      isAutoAssignEnabled: boolean
    },
  ) {
    await RidaApiService.putSassFleetOperator(`v1/user/${userId}/detail`, {
      first_name: details.firstName,
      last_name: details.lastName,
      preferred_name: details.preferredName,
      email: details.email,
      phone_number: details.phoneNumber,
      is_auto_assign_enabled: details.isAutoAssignEnabled,
      user_roles: details.userRoleTypeId
        ? [
            {
              user_role_type_id: details.userRoleTypeId,
            },
          ]
        : [],
      user_vehicles:
        details.vehicleTypeId && details.vehicleRegistrationNumber
          ? [
              {
                vehicle_type_id: details.vehicleTypeId,
                vehicle_registration_number: details.vehicleRegistrationNumber,
              },
            ]
          : [],
      user_bank_accounts:
        details.bankId && details.bankAccountNumber
          ? [
              {
                bank_id: details.bankId,
                bank_account_number: details.bankAccountNumber,
              },
            ]
          : [],
      user_groups: _.map(details.groupIds, g => {
        return {
          group_id: g,
        }
      }),
    })
  }

  public static async searchUser(filterOptions?: {
    search?: string
    itemsPerPage?: number
    pageNumber?: number
    userRoleTypeIds?: number[]
    suggestionJobIds?: string[]
  }) {
    const response =
      await RidaApiService.getSassFleetOperator<V1RidaApiGetSassFleetOperatorUserSearchResponse>(
        'v1/user/search',
        {
          search: filterOptions?.search,
          items_per_page: filterOptions?.itemsPerPage,
          page_number: filterOptions?.pageNumber,
          user_role_type_id: filterOptions?.userRoleTypeIds,
          suggestion_for_job_id: filterOptions?.suggestionJobIds,
        },
      )

    return response.data.data
  }

  public static async getAllUserGroups() {
    const response =
      await RidaApiService.getSassFleetOperator<V1RidaApiGetSassFleetOperatorUserGroupResponse>(
        'v1/user/group',
      )

    return response.data.data
  }
}

export { SassFleetOperatorUser as SassFleetOperatorUserService }
