import { Dayjs } from 'dayjs'

import { DEFAULT_FORMAT } from '../Constant'
import { RidaApiService } from '../RidaApi'
import {
  V1RidaApiGetSassFleetOperatorDriverEarningResponse,
  V1RidaApiGetSassFleetOperatorJobAssignmentResponse,
  V1RidaApiGetSassFleetOperatorJobResponse,
  V1RidaApiPutSassFleetOperatorJobAssignmentAssignUserResponse,
} from '../Type'

class SassFleetOperatorJob {
  public static async getJobEarnings(
    date: Dayjs,
    filterOptions?: {
      pageNumber?: number
      itemsPerPage?: number
      search?: string
    },
  ) {
    const response =
      await RidaApiService.getSassFleetOperator<V1RidaApiGetSassFleetOperatorDriverEarningResponse>(
        'v1/driverEarning',
        {
          date: date.format(DEFAULT_FORMAT.DATE),
          items_per_page: filterOptions?.itemsPerPage,
          page_number: filterOptions?.pageNumber,
          search: filterOptions?.search,
        },
      )

    return response.data.data
  }

  public static async getAllJobs(
    date: Dayjs,
    filterOptions?: {
      search?: string
      jobStatusTypeIds?: number[]
      groupIds?: string[]
    },
  ) {
    const response =
      await RidaApiService.getSassFleetOperator<V1RidaApiGetSassFleetOperatorJobResponse>(
        'v1/job',
        {
          search: filterOptions?.search,
          date: date.format(DEFAULT_FORMAT.DATE),
          job_status_type_id: filterOptions?.jobStatusTypeIds,
          group_id: filterOptions?.groupIds,
        },
      )

    return response.data.data
  }

  public static async getAllEarningsForJob(jobId: string) {
    const response =
      await RidaApiService.getSassFleetOperator<V1RidaApiGetSassFleetOperatorDriverEarningResponse>(
        `v1/job/${jobId}/driverEarning`,
      )

    return response.data.data
  }

  public static async updateJobDeliveryStatus(
    jobId: string,
    options: {
      jobStatusTypeId: number
      isDriverCancelled?: boolean
    },
  ) {
    await RidaApiService.putSassFleetOperator(
      `v1/job/${jobId}/deliveryStatus`,
      {
        is_driver_cancelled: options.isDriverCancelled,
        job_status_type_id: options.jobStatusTypeId,
      },
    )
  }

  public static async getAllAssignmentJobs(
    date: Dayjs,
    filterOptions?: {
      userId?: string[]
      jobStatusTypeIds?: number[]
      groupIds?: string[]
    },
  ) {
    const response =
      await RidaApiService.getSassFleetOperator<V1RidaApiGetSassFleetOperatorJobAssignmentResponse>(
        'v1/jobAssignment',
        {
          user_id: filterOptions?.userId,
          date: date.format(DEFAULT_FORMAT.DATE),
          job_status_type_id: filterOptions?.jobStatusTypeIds,
          group_id: filterOptions?.groupIds,
        },
      )

    return response.data.data
  }

  public static async assignUserForJobs(
    jobIds: string[],
    userId: string,
    ignoreWarnings = false,
  ) {
    const response =
      await RidaApiService.putSassFleetOperator<V1RidaApiPutSassFleetOperatorJobAssignmentAssignUserResponse>(
        'v1/jobAssignment/assignUser',
        {
          user_id: userId,
          job_id: jobIds,
          ignore_warnings: ignoreWarnings,
        },
      )

    return response.data.data
  }
}

export { SassFleetOperatorJob as SassFleetOperatorJobService }
