import axios from 'axios'

import { RidaApiService } from './RidaApi'
import { V1RidaApiGetFileUploadUrlResponse } from './Type'

class FileUpload {
  public static async getFileUploadPresignedUrl(data: {
    taskId?: string
    userId?: string
    documentTypeId: number
    extension: string
  }) {
    const response =
      await RidaApiService.get<V1RidaApiGetFileUploadUrlResponse>(
        'v1/fileUpload/url',
        {
          task_id: data.taskId,
          document_type_id: data.documentTypeId,
          file_extension: data.extension,
          user_id: data.userId,
        },
      )

    return response.data.data
  }

  public static async confirmFileUploadSuccess(fileUploadIds: string[]) {
    await RidaApiService.put('v1/fileUpload/confirm', {
      ids: fileUploadIds,
    })
  }

  public static async uploadPresignedUrl(
    url: string,
    data: Blob,
    contentType: string,
  ) {
    await axios.put(url, data, {
      headers: {
        'Content-Type': contentType,
      },
    })
  }
}

export { FileUpload as FileUploadService }
