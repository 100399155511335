import { Context } from '@nuxt/types'

export default (context: Context) => {
  const redirect = context.redirect
  const hasSassFleetOperatorRole =
    context.app.$accessor.user?.hasSassFleetOperatorRole

  if (hasSassFleetOperatorRole) {
    return redirect('/sassFleetOperator/job/all')
  }

  return redirect('/job/all')
}
