import { RidaApiService } from './RidaApi'
import { V1RidaApiGetNotificationSidebarResponse } from './Type'

class Notification {
  public static async getSidebarNotifications() {
    const response =
      await RidaApiService.get<V1RidaApiGetNotificationSidebarResponse>(
        'v1/notification/sidebar',
      )

    return response.data.data
  }

  public static async sendPushNotification(
    pushData: {
      title: string
      description: string
    },
    userIds: string[],
    vehicleTypeIds: number[],
  ) {
    await RidaApiService.post('v1/notification/sendPush', {
      user_id: userIds,
      vehicle_type_id: vehicleTypeIds,
      push_data: {
        title: pushData.title,
        description: pushData.description,
      },
    })
  }
}

export { Notification as NotificationService }
