import { RidaApiService } from './RidaApi'
import { V1RidaApiGetJobIdHistoryResponse } from './Type'

class JobHistory {
  public static async getAllHistoriesForJob(
    jobId: string,
    filterOptions?: {
      pageNumber?: number
      itemsPerPage?: number
    },
  ) {
    const response = await RidaApiService.get<V1RidaApiGetJobIdHistoryResponse>(
      `v1/job/${jobId}/history`,
      {
        items_per_page: filterOptions?.itemsPerPage,
        page_number: filterOptions?.pageNumber,
      },
    )

    return response.data.data
  }
}

export { JobHistory as JobHistoryService }
